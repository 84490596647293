const Utils = window.relevantDigital.import('Utils');

const { relevantDigital } = window;

let enabled;
let rlvPubx;
let floors;
let scriptLoaded;
let hasError;
let noFloors;
let loadFloorsFn;

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
const floorUrl = () => `https://floor.pbxai.com/?pubxId=${rlvPubx.pubxId}&page=${Utils.getLocation()?.href}`;
const jsUrl = () => `https://cdn.pbxai.com/${rlvPubx.pubxId}.js?pbxd=${encodeURIComponent(Utils.getLocation()?.origin)}`;
const isHybrid = () => rlvPubx.strategy === 'hybrid';

const getStatus = (auction) => {
	if (!enabled) {
		return 'off';
	}
	if (noFloors) {
		return 'no_floors';
	}
	if (hasError) {
		return 'error';
	}
	if (!scriptLoaded || (isHybrid() && !floors)) {
		return 'timeout';
	}
	const adUnit = auction.finalAdUnits.find(({ bids }) => bids.length);
	if (!adUnit) {
		return 'no_prebid';
	}
	const { targVals } = auction.pubxData;
	if (!targVals) {
		return 'confusion1';
	}
	const targ = Utils.assign({}, ...targVals.map(([, obj]) => obj));
	if (!targ['pubx-a']) {
		return 'no_targeting';
	}
	if (targ['pubx-a'] === 'off' || targ['pubx-op'] === 'off' || !targ['pubx-floor']) {
		if (adUnit.bids[0]?.floorData?.skipped) {
			return 'skipped';
		}
		return 'no_floors';
	}
	const mv = targ['pubx-m_v'];
	if (typeof mv !== 'string' || !mv) {
		return 'confusion2';
	}
	if (mv.indexOf('z') >= 0) {
		return 'defaults';
	}
	return 'on';
};

// Will be called both when getting adserver-targeting and HBA custom parameters, however result is cached and
// will be the same for both */
const getStatusParams = (auction) => {
	const { pubxData = {} } = auction;
	if (!pubxData.pubxStatusParams) {
		const values = {
			enabled: enabled ? 'on' : 'off',
			status: getStatus(auction),
		};
		const params = { custom: {}, ads: {} };
		[['custom', 'PubX ', true], ['ads', 'rlv_pubx_']].forEach(([pfx, start, pretty]) => {
			Utils.entries(values).forEach(([key, val]) => {
				if (rlvPubx[`${pfx}${capitalize(key)}`]) {
					params[pfx][`${start}${key}`] = pretty ? capitalize(val).replace('_', ' ') : val;
				}
			});
		});
		pubxData.pubxStatusParams = params;
	}
	return pubxData.pubxStatusParams;
};

const onBeforeAdRequest = ({ isHbLess, auction }) => {
	const targVals = Utils.entries(window.__pubxFloors__?.[auction.auctionId]);
	targVals.forEach(([code, targ]) => {
		const { slot } = auction.usedUnitDatas.find((u) => u.code === code) || {};
		const setTarg = (s) => s?.updateTargetingFromMap?.(targ);
		slot?.waitGamSlot?.(setTarg);
		setTarg(slot);
	});
	if (!isHbLess) { // Else, we'll get another call
		auction.pubxData.targVals = Utils.clone(targVals);
		auction.pubxData.adReqDone.trigger();
	}
};

const onAuctionInitDone = ({ auction }) => {
	auction.pubxData = { adReqDone: Utils.onceEvent() };
	if (enabled) {
		auction.pbjsCalls = auction.pbjsCalls || {};
		const { pbjsCalls } = auction;
		pbjsCalls.setConfig = pbjsCalls.setConfig || ((...args) => auction.pbjs.setConfig(...args));
		pbjsCalls.setConfig = Utils.hookBefore(pbjsCalls.setConfig, (cfg) => {
			delete (cfg || {}).floors; // Remove any floors settings supplied from elsewhere when using PubX
		});
	}
	// Add custom parameters + ad server key-values
	const injects = [
		[auction, 'getHbaSystemParams', 'custom'],
		...auction.adservers.map((a) => [a, 'getGlobalTargeting', 'ads']),
	];
	injects.forEach(([obj, fnName, type]) => Utils.addCbs(obj, {
		[fnName]: (res) => ({ ...getStatusParams(auction)[type], ...res }),
	}, { when: 'after' }));
};

const loadJs = () => relevantDigital.getInstance().loadOnce(jsUrl(), ({ error }) => {
	if (!error) {
		scriptLoaded = true;
	} else {
		hasError = true;
	}
});

const setConfig = (cfg) => relevantDigital.getInstance().pbjs.que.push(() => (
	relevantDigital.getInstance().pbjs.setConfig(cfg)
));

const loadFloors = (cb, timeout) => {
	loadFloorsFn((res, { age, isTimeout }) => {
		if (age && age > rlvPubx.maxAge * 3600 * 1000) {
			cb?.();
			return; // We've timed out but the cached floors was too old
		}
		if (res !== undefined) {
			window.__pubxFloorFetchStatus__ = 'SUCCESS';
			noFloors = !floors && res === false;
			if (res) {
				floors = { data: res };
				setConfig({ floors });
				window.__pubxFloorsConfig__ = { floors };
			}
		} else if (!isTimeout) {
			hasError = true;
			window.__pubxFloorFetchStatus__ = 'ERROR';
		} // else timeout => do nothing
		cb?.();
	}, timeout, { waitIfOlderMs: 0 });
};

const initLoadFloors = () => {
	const { timeout } = rlvPubx;
	const instance = relevantDigital.getInstance();
	setConfig({
		floors: {
			enforcement: { floorDeals: true },
			...(!isHybrid() && {
				auctionDelay: timeout,
				endpoint: { url: floorUrl() },
			}),
			data: {
				floorProvider: 'PubxFloorProvider',
				modelVersion: 'cb_AB_z',
				currency: 'USD',
				schema: { fields: ['mediaType'] },
				values: { banner: 0.01 },
			},
		},
	});
	if (!isHybrid()) {
		return;
	}
	window.__pubxFloorFetchStatus__ = 'IN_PROGRESS';
	loadFloorsFn = instance.storage.urlCaches.onceLoader({
		url: floorUrl(),
		useGet: true,
		parse: (s) => (!s ? false : JSON.parse(s)),
		storageKey: 'pubx',
		useStorage: true,
	});
	loadFloors();
};

const injectLoadPrebid = () => {
	const orgFn = relevantDigital.loadPrebid;
	relevantDigital.loadPrebid = function (...args) {
		loadFloors(() => orgFn.call(this, ...args), rlvPubx.timeout);
	};
};

const waitAdRequest = (auction, done) => {
	const { adReqDone } = auction?.pubxData || {};
	if (adReqDone) {
		adReqDone.wait(done);
	} else {
		done();
	}
};

const initHbaDelay = () => {
	window.RELEVANT_ANALYTICS_SETTINGS = window.RELEVANT_ANALYTICS_SETTINGS || {};
	const { RELEVANT_ANALYTICS_SETTINGS: hbaConfig } = window;
	const { waitInit: prevWaitFn } = hbaConfig;
	hbaConfig.waitInit = function (done, hbaAuction, ...rest) {
		const doCall = () => waitAdRequest(hbaAuction.hbmAuction, done);
		if (prevWaitFn) {
			prevWaitFn.call(this, doCall, hbaAuction, ...rest);
		} else {
			waitAdRequest(hbaAuction.hbmAuction, done);
		}
	};
};

const init = () => {
	const instance = relevantDigital.getInstance();
	rlvPubx = instance.siteData.rlvPubx || {};
	const { pubxId, perc, strategy } = rlvPubx;
	if (!pubxId || !perc) {
		return;
	}
	const [, forceVal] = Utils.getLocation()?.toString()?.split('rlv_pubx_force=') || [];
	enabled = forceVal ? !!parseInt(forceVal, 10) : perc / 100 >= Math.random();
	relevantDigital.addAuctionCallbacks({ onBeforeAdRequest, onAuctionInitDone });
	initHbaDelay();
	if (!enabled) {
		return;
	}
	initLoadFloors();
	loadJs();
	if (strategy === 'hybrid') {
		injectLoadPrebid();
	}
};

relevantDigital.cmd.unshift(init);
